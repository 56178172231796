<template>
  <CCard>
      <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.yardList')}}</b>
      </CCardHeader>
      <CCardBody>
          <CRow>
              <CCol col="12" xl="12">
                  <dataTableExtended
                      class="align-center-row-datatable"
                      hover
                      sorter
                      small
                      column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :items="formatedItems"
                      :fields="fields"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="tableText.itemsPerPage"
                      pagination
                  >
                      <template #number="{ index }">
                          <td class="text-center">
                              {{index+1}}
                          </td>
                      </template>
                      <template #action="{item, index}">
                          <td class="text-center">
                              <CButton
                                  color="watch"
                                  square
                                  size="sm"
                                  class="mr-1"
                                  v-c-tooltip="{
                                      content: $t('label.yard'),
                                      placement: 'top-start'
                                  }"
                                  @click="updateDetaildYard(item, index)"
                              >
                                  <CIcon name="eye"/>
                              </CButton>
                          </td>
                      </template>
                  </dataTableExtended>
              </CCol>
          </CRow>
      </CCardBody>
  </CCard>
</template>
<script>
  import GeneralMixin from '@/_mixins/general';
  import { mapState } from 'vuex';
  import { DateFormater} from '@/_helpers/funciones';

  //data
  function data() {
    return {
      dataYard :[],
    }
  }

  
  //methods
  function updateDetaildYard(item){
    this.$store.state.yardManagement.yardData = item;
    this.$store.state.yardManagement.yardCollapse = 1;
  }

  function YardList() {
    this.$store.state.yardManagement.loading = true;
    let YardUserJson = [{
      CompanyBranchId: this.branch.CompanyBranchId 
    }];

    this.$http.post("YardManagement-list", YardUserJson, { root: 'YardUserJson' })
    .then(response => {
      this.dataYard = response.data.data ? response.data.data : [];
      this.$store.state.yardManagement.yardList = response.data.data ? response.data.data : [];
      if (!this.roleSelected?.FgGeneral) {
        let YardData = this.dataYard.find(item => item.YardId == this.YardSelected);
        this.$store.state.yardManagement.yardData = YardData ?? {};
        if (!YardData) {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.YardNotConfiguationAssigned'),
            type: "error"
          });
        }else{
          this.$store.state.yardManagement.yardCollapse = 1;
        }
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
        });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }


  //computed
  function VisitOptions(){
    if(this.ListVisit.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.ListVisit.map(function(e){
        chart.push({
          value: e.IncidenceGpoId, 
          label: e.IncidenceGpoNameEn,
        })    
      })
      return chart;
    }
  }

  function formatedItems() {
    let _lang = this.$i18n.locale;
    return this.dataYard.map((item) => { 
        return {
          ...item,
          TpYardName: _lang=='en' ? item.TpYardNameEn : item.TpYardNameEs,
          LastDispatch: item.LastDispatch ? DateFormater.formatTimeZoneToDateTime(item.LastDispatch) : 'N/A',
          LastReception: item.LastReception ? DateFormater.formatTimeZoneToDateTime(item.LastReception) : 'N/A',
        };
    });
  }

  function fields(){
    return [
        { 
          key: 'action', 
          label: '', 
          _style: 'width:50px; text-align:center;', 
          sorter: false, 
          filter: false
        },
        { key: 'number', label: '',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
        { key: 'YardName', label: this.$t('label.yard'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'YardAlias', label: this.$t('label.alias'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
        { key: 'TpYardName', label: this.$t('label.yardType'), _style:'min-width: 180px;', _classes:"text-center"},
        { key: 'YardGpoName',label: this.$t('label.alpha'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'TeusCapacity', label: this.$t('label.teus'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
        { key: 'TeusOccupied', label: this.$t('label.occupiedTeus'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
        { key: 'TeusAvailable', label: this.$t('label.availableTeus'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
        // { key: '', label: this.$t('label.equipmetToPosition'),  _style:'min-width: 210px; text-align:center;', _classes:"text-center" },
        { key: 'LastReception', label: this.$t('label.lastEntry'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
        { key: 'LastDispatch', label: this.$t('label.lastDespatch'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
    ];
  }

  export default{
    name: 'list-yard',
    data,
    mixins: [GeneralMixin],
    components: {
    },
    methods: {
      updateDetaildYard,
      YardList
    },
    computed: {
      VisitOptions,
      fields,
      formatedItems,
      ...mapState({
        roleSelected: state => state.auth.role,
        branch: state => state.auth.branch,
        yardCollapse: state => state.yardManagement.yardCollapse,
        YardSelected: state => state.yardManagement.YardSelected,
      }),
    },
    mounted: YardList,
    watch: {
      yardCollapse: function (val) {
        if (val==0 && this.roleSelected?.FgGeneral) {
          this.YardList();
        }
      },
      YardSelected: function (NewVal) {
        this.$store.state.yardManagement.loading = true;
        this.$store.state.yardManagement.isSelectYard = true;
        this.$store.state.yardManagement.yardCollapse = 0;
        let YardData = this.dataYard.find(item => item.YardId == NewVal);
        this.$store.state.yardManagement.yardData = YardData ?? {};
        if (YardData) {
          this.$store.state.yardManagement.tabinfoIndex = 0;
          this.$store.state.yardManagement.ContainerTabIndex = 0;
          this.$store.state.yardManagement.tabMovementIndex = 0;
          this.$store.state.yardManagement.dropContainerMovement = 0;
          this.$store.state.yardManagement.dropBlMovement = 0;
          this.$store.state.yardManagement.dropItemEvent = 0;
          this.$store.state.yardManagement.dropItemReport = 0;
          this.$store.state.yardManagement.dropItemMovementList = 0;
          this.$store.state.yardManagement.dropItemMovementReception = 0;
          this.$store.state.yardManagement.dropItemMovementDispatch = 0;
          this.$store.state.yardManagement.dropItemInventary = 0;
          this.$store.state.yardManagement.dropItemInspection = 0;
          this.$store.state.yardManagement.tabIndex = 0;
          this.$store.state.yardManagement.isSelect = true;
          this.$store.state.yardManagement.GeneralList = 0;
          this.$store.state.yardManagement.dataContainer = {};
          this.$store.state.yardManagement.ListReceivedContainers = [];
          this.$store.state.yardManagement.ListDispatchedContainers = [];
          this.$store.state.yardManagement.ListRecognitionContainers = [];
          this.$store.state.yardManagement.containerSelected = '';
          this.$store.state.yardManagement.globalSelectContainer = [];
          this.$store.state.yardManagement.ContainerStatusInfo = {};
          this.$store.state.yardManagement.yardCollapse = 1;
        }else{
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.YardNotConfiguationAssigned'),
            type: "error"
          });
        }
        this.$store.state.yardManagement.loading = false;
      }
    }
  }

</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>